import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

const FooterMenu = ({ menuList }) => {
    return (
        <ul>
            {menuList?.map((item) => (
                <li key={item.id} className="mb-4 last:mb-0">
                    <Link to={item?.link}>
                        <div className="text-[#0EA478]">{item?.text}</div>
                    </Link>
                </li>
            ))}
        </ul>
    );
};

FooterMenu.propTypes = {
    menuList: PropTypes.array,
};

export default FooterMenu;
