import React, { Fragment } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import ContactInfo from "../../../components/widgets/contact-info";
import FooterMenu from "../../../components/widgets/menu-list";
import Socials from "../../../components/widgets/socials";

const Footer = ({ data }) => {
    return (
        <footer className="footer-section">
            <div className="footer-top section-space-pt">
                <div className="container-full">
                    <div className="grid gap-y-9 grid-cols-1 sm:grid-cols-12 border-b section-space-pb">
                        <div className="sm:col-span-6 lg:col-span-4">
                            <Link to="/" className="block max-w-[180px]">
                                <StaticImage
                                    src="../../../data/images/logo.png"
                                    alt="中衛貨運"
                                />
                            </Link>
                        </div>
                        <div className="sm:col-span-6 lg:col-span-4">
                            <ContactInfo
                                contactInfo={data?.footer[1]?.contact_info}
                            />
                        </div>
                        <div className="sm:col-span-6 lg:col-span-2">
                            <FooterMenu menuList={data?.footer[2]?.list} />
                        </div>
                        <div className="sm:col-span-6 lg:col-span-2">
                            <Socials socials={data?.footer[0]?.socials} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <div className="container-full">
                    <div className="flex flex-col gap-6 md:flex-row md:justify-between items-center py-12">
                        <div className="copyright">
                            &copy; {new Date().getFullYear()}
                            <i className="icofont-heart"></i> BY{" "}
                            <a
                                className="hover:text-primary"
                                href="https://hasthemes.com/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <span className="text-black">中衛貨運</span>
                            </a>{" "}
                            All Rights Reserved
                        </div>
                        {/* <ul className="footer-language flex space-x-6 justify-content-center">
                            <li>
                                <a href="/">Eng</a>
                            </li>
                            <li>
                                <a href="/">Rus</a>
                            </li>
                        </ul> */}
                    </div>
                </div>
            </div>
        </footer>
    );
};
Footer.propTypes = {
    data: PropTypes.shape({
        footer: PropTypes.arrayOf(
            PropTypes.shape({
                contact_info: PropTypes.array,
                list: PropTypes.array,
                socials: PropTypes.array,
            })
        ),
    }),
};
export default Footer;
