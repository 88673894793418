import PropTypes from "prop-types";
import React, { Fragment, useState } from "react";
import { Link } from "gatsby";
import MainMenu from "../../../components/menu/main-menu";
import MainMenuLightBG from "../../../components/menu/main-menu-light-bg";
import MobileNavMenu from "../../../components/menu/mobile-menu";
import Button from "../../../components/shared/button";
import { useSticky } from "../../../hooks";
import { StaticImage } from "gatsby-plugin-image";

const Header = ({ data }) => {
    // Sticky Header
    const { sticky, headerRef, fixedRef } = useSticky();

    // OfCanvas Menu
    const [ofcanvasOpen, setOfcanvasOpen] = useState(false);

    // OfCanvas Menu Open & Remove
    const ofcanvasHandaler = () => {
        setOfcanvasOpen((prev) => !prev);
    };
    return (
        <header ref={headerRef}>
            <div
                ref={fixedRef}
                className={` bg-transparent w-full mx-auto z-40 header-top  ${
                    sticky
                    // ? "sticky-header !fixed  bg-blue-300 w-full" : ""
                }`}
            >
                <div className="container-full">
                    <nav className="bg-transparent flex justify-between items-center">
                        <div className="header-left-side flex items-center">
                            <Link
                                to="/"
                                className="block py-5 lg:mr-[80px] xl:mr-[180px]"
                            >
                                {sticky ? (
                                    <StaticImage
                                        className="max-w-[180px]"
                                        src="../../../data/images/logo.png"
                                        alt="中衛貨運"
                                    />
                                ) : (
                                    <StaticImage
                                        className="max-w-[180px]"
                                        src="../../../data/images/logo.png"
                                        alt="中衛貨運"
                                    />
                                )}
                            </Link>
                            {sticky ? (
                                <MainMenu allmenuData={data?.menu} />
                            ) : (
                                <MainMenuLightBG allmenuData={data?.menu} />
                            )}
                        </div>
                        <div className="header-right-action flex items-center">
                            {sticky ? (
                                <Button
                                    path="/contact-us"
                                    size="sm"
                                    color="transparent"
                                    hover="hoverPrimary"
                                    outline="light"
                                    className="hidden sm:block rounded-2xl"
                                >
                                    立即聯繫
                                </Button>
                            ) : (
                                <Button
                                    path="/contact-us"
                                    size="sm"
                                    color="transparent"
                                    hover="hoverPrimary"
                                    outline="dark"
                                    className="hidden sm:block rounded-2xl"
                                >
                                    立即聯繫
                                </Button>
                            )}
                            {sticky ? (
                                <button
                                    onClick={ofcanvasHandaler}
                                    onKeyDown={ofcanvasHandaler}
                                    className="flex flex-col space-y-1.5 ml-8 lg:hidden"
                                >
                                    <span className="line h-0.5 w-6 inline-block bg-white"></span>
                                    <span className="line h-0.5 w-6 inline-block bg-white"></span>
                                    <span className="line h-0.5 w-6 inline-block bg-white"></span>
                                </button>
                            ) : (
                                <button
                                    onClick={ofcanvasHandaler}
                                    onKeyDown={ofcanvasHandaler}
                                    className="flex flex-col space-y-1.5 ml-8 lg:hidden"
                                >
                                    <span className="line h-0.5 w-6 inline-block bg-black"></span>
                                    <span className="line h-0.5 w-6 inline-block bg-black"></span>
                                    <span className="line h-0.5 w-6 inline-block bg-black"></span>
                                </button>
                            )}

                            <MobileNavMenu
                                MobilemenuData={data?.menu}
                                ofcanvasOpen={ofcanvasOpen}
                                ofcanvasHandaler={ofcanvasHandaler}
                            />
                        </div>
                    </nav>
                    <span className="inner-border border-b block"></span>
                </div>
            </div>
        </header>
    );
};

Header.propTypes = {
    data: PropTypes.shape({
        menu: PropTypes.arrayOf(PropTypes.shape({})),
    }),
};

export default Header;
